import {
  ArchiveBoxIcon,
  InboxIcon,
  BuildingStorefrontIcon,
  CalendarIcon,
  ListBulletIcon,
  PrinterIcon,
  ScaleIcon,
  ShoppingCartIcon,
  TruckIcon,
} from "@heroicons/vue/24/outline/index";
import { useNotifications } from "~/stores/notifications.js";

export const useMenu = () => {
  const notify = useNotifications();
  const { t } = useI18n();

  return [
    {
      id: "tables",
      text: "Tables",
      icon: BuildingStorefrontIcon,
      to: "/tables",
      match: null,
    },
    {
      id: "takeaway",
      text: "Takeaway",
      icon: TruckIcon,
      to: "/takeaway-orders",
      match: null,
    },
    {
      id: "reservations",
      text: "Reservations",
      icon: CalendarIcon,
      to: "/reservations",
      match: null,
    },
    {
      id: "checkout",
      text: "Checkout",
      icon: ShoppingCartIcon,
      to: "/orders/create",
      match: null,
    },
    {
      id: "orders",
      text: "Orders",
      icon: ListBulletIcon,
      to: "/orders",
      match: (path) => path !== "/orders/create" && path.startsWith("/orders"),
    },
    {
      id: "stock",
      text: "Stock",
      icon: ArchiveBoxIcon,
      to: "/stock",
      match: null,
    },
    {
      id: "print-jobs",
      text: "Print Jobs",
      icon: PrinterIcon,
      to: "/print-jobs",
      match: null,
    },
    {
      id: "settle",
      text: "Settlement",
      icon: ScaleIcon,
      to: "/settlement",
      match: null,
    },
    {
      id: "open-cash-box",
      text: "Open Cash Box",
      icon: InboxIcon,
      match: null,
      onClick: async () => {
        await useApi("/cash-box-openings", {
          method: "POST",
        });

        notify.success(t("Cash Box Opened"));
      },
    },
  ];
};
